import { NavItem } from '@/app/[lang]/(web)/_components/Navbar';
import Link from 'next/link';
import React from 'react';

export const getHref = (href: string, lang: string) => {
  return href.startsWith('http') ? href : `/${lang}${href}`;
};
interface FooterLinkGroupProps {
  title: string;
  dataList: NavItem[];
  t: any;
  lang: string;
}

const FooterLinkGroup = ({
  title,
  dataList,
  t,
  lang,
}: FooterLinkGroupProps) => {
  return (
    <ul className="flex flex-col gap-4 pl-4">
      <li className="typo-label-xxl">{t[title]}</li>
      {dataList.map((data) => (
        <li key={data.id} className="">
          <Link
            href={getHref(data.href, lang)}
            {...(data.href.startsWith('http') && {
              target: '_blank',
              rel: 'noopener noreferrer',
            })}
          >
            <span className="text-gray-800 hover:text-gray-900">
              {t[data.text]}
            </span>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default FooterLinkGroup;
