import { ByPageData } from '@/types/interface';

export enum SubmissionValidUntilStateEnum {
  Expired = 0,
  Pending = 1,
  Valid = 2,
  None = 3,
}

export enum SubmissionExpirationStateEnum {
  Ongoing = 0,
  Overdue = 1,
  None = 2,
}

export const SubmissionValidUntilState: Record<
  SubmissionValidUntilStateEnum,
  string
> = {
  [SubmissionValidUntilStateEnum.Expired]: 'Expired',
  [SubmissionValidUntilStateEnum.Pending]: 'Pending',
  [SubmissionValidUntilStateEnum.Valid]: 'Valid',
  [SubmissionValidUntilStateEnum.None]: 'None',
};

export const SubmissionExpirationState: Record<
  SubmissionExpirationStateEnum,
  string
> = {
  [SubmissionExpirationStateEnum.Ongoing]: 'Ongoing',
  [SubmissionExpirationStateEnum.Overdue]: 'Overdue',
  [SubmissionExpirationStateEnum.None]: 'None',
};

export enum SubmissionTodoTypeEnum {
  AllTodo = 1,
  MyTurn = 2,
  Ongoing = 3,
  Done = 4,
}

export const SubmissionTodoTypeConstant: Record<
  SubmissionTodoTypeEnum,
  {
    label: string;
    value: SubmissionTodoTypeEnum;
  }
> = {
  [SubmissionTodoTypeEnum.AllTodo]: {
    label: 'All',
    value: SubmissionTodoTypeEnum.AllTodo,
  },
  [SubmissionTodoTypeEnum.MyTurn]: {
    label: 'My Turn',
    value: SubmissionTodoTypeEnum.MyTurn,
  },
  [SubmissionTodoTypeEnum.Ongoing]: {
    label: 'Ongoing',
    value: SubmissionTodoTypeEnum.Ongoing,
  },
  [SubmissionTodoTypeEnum.Done]: {
    label: 'Done',
    value: SubmissionTodoTypeEnum.Done,
  },
};

export interface CreateSubmissionRequest {
  templateId: number;
  expirationAt: string; // ISO 8601 format
  daysOfReminderBeforeExpiry: number;
}

export interface SubmissionsQuery {
  templateId: number;
  pageNumber?: number;
  pageCount?: number;
  orderBy?: string;
  isOrderByDesc?: boolean;
  keyword?: string;
}

export interface Submitter {
  userId: number;
  fullName: string;
  email: string;
  photoUrl: string;
  abbreviationBgColor: string;
}

export enum ValidUntilStateEnum {
  Expired = 0,
  Pending = 1,
  Valid = 2,
  None = 3,
}

export enum ExpirationStateEnum {
  Ongoing = 0,
  Overdue = 1,
  None = 2,
}

export interface SubmissionData {
  currentStepName: string;
  expirationAt: string;
  expirationState: ExpirationStateEnum;
  fromActionColor: string | null;
  fromActionName: string | null;
  invalidAt: string | null;
  referenceNumberStr: string;
  submissionId: number;
  submittedAt: string | null;
  submitter: Submitter;
  validUntilState: ValidUntilStateEnum;
}
export interface TrashedSubmissionData extends SubmissionData {
  trashedAt: string;
  trashedBy: string;
  isLastStep: boolean;
}

export interface Submission {
  currentPageCount: number;
  data: SubmissionData[];
  pageCount: number;
  pageNumber: number;
  totalCount: number;
  totalPageCount: number;
}

export interface SubmissionDetail {
  created: string;
  submissionId: number;
  templateId: number;
  submitter: Submitter;
  submittedAt: string | null;
  referenceNumberStr: string;
  currentStepId: string;
  expirationAt: string;
  validTime: string | null;
  pages: any[] | null;
  photos: any[];
  files: any[];
  isLastStep: boolean;
  validUntilState: SubmissionValidUntilStateEnum;
  expirationState: SubmissionExpirationStateEnum;
  updated: string;
}

export interface SubmitStepRequest {
  submissionId: number;
  projectId: number;
  stepId: string;
  actionId: string;
}

export interface SaveStepRequest {
  submissionId: number;
  projectId: number;
  stepId: string;
  pages: any[];
  changes?: Changes[];
}

export interface Changes {
  fieldName: string;
  oldValue: string;
  newValue: string;
}

export interface SubmissionProgress {
  stepRecords: StepRecord[];
}

export interface StepRecord {
  stepId: string;
  submissionProcessId: number;
  version: string | null;
  submitter: Submitter | null;
  submittedAt: string | null;
  submitActionId?: string | null;
  nextNodeType?: string;
  isRevokedStep?: boolean;
}

export interface BriefQueryParams {
  templateId: number;
  orderBy?: string;
  isOrderByDesc?: boolean;
  keyword?: string;
  pageCount?: number;
  pageNumber?: number;
  currentStepId?: string;
  validUntilState?: SubmissionValidUntilStateEnum;
  expirationState?: SubmissionExpirationStateEnum;
}

interface BriefData {
  submissionId: number;
  referenceNumber: number;
  referenceNumberStr: string;
}

export interface Brief {
  currentPageCount: number;
  pageNumber: number;
  pageCount: number;
  totalCount: number;
  totalPageCount: number;
  data: BriefData[];
}

export interface ProcessDetailRequest {
  submissionId: number;
  version: string;
}

export interface CreateSubformParams {
  formTemplateIds: number[];
  submissionId: number;
  projectId: number;
  stepId: string;
  subformTemplateName: string;
}

export interface SubmissionTodoParams {
  todoType: SubmissionTodoTypeEnum;
  orderBy?: string;
  isOrderByDesc?: boolean;
  keyword?: string;
  pageCount?: number;
  pageNumber?: number;
}

export type SubmissionTodoByPage = ByPageData<SubmissionTodoData>;

export interface SubmissionTodoData {
  created: string;
  currentStepName: string;
  expirationAt: string;
  expirationState: number;
  fromActionColor: string | null;
  fromActionName: string | null;
  invalidAt: string | null;
  isLastStep: boolean;
  project: {
    color: string;
    icon: string;
    name: string;
    projectId: number;
  };
  referenceNumberStr: string;
  submissionId: number;
  submissionTemplateName: string;
  submitter: {
    abbreviationBgColor: string;
    email: string;
    fullName: string;
    isDelete: boolean;
    nameAbbreviation: string;
    photoUrl: string | null;
    userId: number;
  };
  todoType: number;
  validUntilState: number;
}
