const EXTERNAL_LINKS = {
  SOCIAL_MEDIA: {
    FACEBOOK:
      'https://www.facebook.com/people/GoBuid-Technologies/61555612730233/',
    X: '',
    LINKEDIN: '',
    INSTAGRAM: '',
    YOUTUBE_CHANNEL: 'https://www.youtube.com/@MarketingGB-r2x',
    YOUTUBE_VIDEO: 'https://youtu.be/IfFL40Jai98?si=I3IlF-iLZbixPRnr',
    YOUTUBE_VIDEO_ID: 'IfFL40Jai98',
    YOUTUBE_PLAYLIST: '',
  },
  SERVICE: {
    APP_STORE: 'https://apps.apple.com/us/app/gobuid/id6468765101',
    APP_STORE_ID: '6468765101',
    GOOGLE_PLAY:
      'https://play.google.com/store/apps/details?id=com.gobuid&pcampaignid=web_share',
    HELP_CENTER: '',
    CONTACT_SALES:
      'https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ38U2Izz6QtBDDLjGDcakCzNAmOhLMUdrkxnIg9I9L_c6p5in1_MXocTkyvdPWio9HVymu8EkBy',
  },
} as const;

export default EXTERNAL_LINKS;
