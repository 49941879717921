import { toast } from 'react-hot-toast';
import { ReactNode } from 'react';
import { BaseIcon } from '../BaseIcon/BaseIcon';

export type ToastType =
  | 'default'
  | 'Neutral'
  | 'neutral'
  | 'normal'
  | 'Informative'
  | 'informative'
  | 'info'
  | 'base'
  | 'Positive'
  | 'positive'
  | 'success'
  | 'Negative'
  | 'negative'
  | 'error';

export interface BaseToastProps {
  message: string | ReactNode;
  type: ToastType;
  duration?: number;
}

/**
 * 根據傳入的類型和訊息來顯示一個 Toast。
 *
 * @param type - Toast 的類型。
 * @param message - 要顯示的訊息。
 * @param duration - ms
 */
const BaseToast = (type = 'default', message, duration = 5000) => {
  const toastStyles: Record<ToastType, string> = {
    default: 'bg-black',
    Neutral: 'bg-black',
    neutral: 'bg-black',
    normal: 'bg-black',
    Informative: 'bg-primary-700',
    informative: 'bg-primary-700',
    info: 'bg-primary-700',
    base: 'bg-primary-700',
    Positive: 'bg-secondary-700',
    positive: 'bg-secondary-700',
    success: 'bg-secondary-700',
    Negative: 'bg-complementary-red-900',
    negative: 'bg-complementary-red-900',
    error: 'bg-complementary-red-900',
  };

  const template = (
    <div
      className={`${toastStyles[type] || 'bg-black'} typo-paragraph-sm flex max-w-[375px] items-center justify-between gap-2 rounded-xl p-4`}
    >
      <div className="w-full text-white">{message || ''}</div>
      <button
        type="button"
        onClick={() => {
          toast.remove(toastId);
        }}
        className="flex size-7 shrink-0 grow-0 items-center justify-center"
      >
        <BaseIcon name="CloseDefault" size={16} color="#FEFEFE" />
      </button>
    </div>
  );

  const toastId = toast.custom(template, {
    position: 'bottom-left',
    duration,
  });
};

export default BaseToast;
