import BaseSwitch from '@/components/base/BaseSwitch/BaseSwitch';

interface PlanToggleProps {
  isYearly: boolean;
  onChange: (isYearly: boolean) => void;
  t: any;
}

const PlanToggle = ({ isYearly, onChange, t }: PlanToggleProps) => {
  return (
    <div className="flex items-center justify-center gap-2 px-1 py-2">
      <p
        className="typo-label-xxl"
        style={{
          color: isYearly ? '#8C909B' : '#262D42',
        }}
      >
        {t.planSectionMonthly}
      </p>
      <BaseSwitch
        value={isYearly}
        onChange={() => onChange(!isYearly)}
        ariaLabel="plan-toggle"
      />
      <p
        className="typo-label-xxl"
        style={{
          color: isYearly ? '#262D42' : '#8C909B',
        }}
      >
        {t.planSectionYearly}{' '}
        <span className="typo-label-xs text-gray-700">{t.planYearlyOff}</span>
      </p>
    </div>
  );
};

export default PlanToggle;
