import {
  FormDetail,
  FormPageData,
  WorkflowValidation,
} from '@/lib/form/formTemplateSetting/formTemplateSetting.interface';
import { formTemplateSettingApi } from '@/lib/form/formTemplateSetting/formTemplateSettingAPI';
import { StepRecord } from '@/lib/submissions/submissions.interface';
import { workflowStepToStepRecords } from '@/lib/submissionTemplates/submissionTemplateSettingSlice';
import { GetTeams } from '@/lib/teams/interface';
import {
  DeviceType,
  Orientation,
} from '@app/form/template/[formTemplateId]/_component/Preview/preview.interface';
import { cardStepDefaultData } from '@app/form/template/[formTemplateId]/_component/Workflow/CardData';
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { utils } from '@gobuid/flow-template';

const { validateFlowData } = utils;

export const workflowStepMap = (nodes: any[]) => {
  if (!nodes) return [];
  return nodes
    .filter((node) => node.type === 'step')
    .map((v) => ({
      id: v.id,
      type: v.type,
      teamIds: v.data.teamIds,
      name: v.data.stepName,
      pageIds: v.data.pageIds,
    }));
};

export const workflowButtonMap = (nodes: any[]) => {
  if (!nodes) return [];
  return nodes
    .filter((node) => node.type === 'button')
    .map((v) => ({
      id: v.id,
      type: v.type,
      name: v.data.name,
      color: v.data.color,
    }));
};
interface InitialState {
  pages: FormPageData[] | null;
  photos: any[] | null;
  files: any[] | null;
  tempResource: {
    photos: any[];
    files: any[];
  };
  fieldSettingVisible: boolean;
  fieldMenuVisible: boolean;
  preview: {
    device: DeviceType;
    orientation: Orientation;
    isEditMode: boolean;
    copyPages: FormPageData[] | null;
    selectedStep: {
      id: string;
      value: string;
      label: string;
    };
    selectedTeamId: number | null;
  };
  workflow: {
    nodes: any[] | null;
    edges: any[] | null;
    steps: any[] | null;
    teams: GetTeams[] | null;
    buttons: any[] | null;
    validation: WorkflowValidation;
  };
  formDetail: FormDetail | null;
  isFormDetailReady: boolean;
  view: {
    stepRecords: StepRecord[] | null;
    isStepInfoVisible: boolean;
  };
}

const initialState: InitialState = {
  pages: [],
  photos: [],
  files: [],
  tempResource: {
    photos: [],
    files: [],
  },
  fieldSettingVisible: false,
  fieldMenuVisible: true,
  preview: {
    device: 'phone',
    orientation: 'portrait',
    isEditMode: false,
    copyPages: [],
    selectedStep: {
      id: '',
      value: '',
      label: '',
    },
    selectedTeamId: null,
  },
  workflow: {
    nodes: [],
    edges: [],
    teams: [],
    steps: [],
    buttons: [],
    validation: {
      isAllSendEmail: false,
      isAllButtonClose: false,
      isAllBackToPreviousClose: false,
      isAllCreateNewVersionClose: false,
      isAllClose: false,
    },
  },
  formDetail: {
    name: '',
    folderName: '',
    folderId: null,
    referenceNumberPrefix: '',
    referenceNumberSuffix: '',
    referenceNumberStartsFrom: '',
    validTimeStepId: null,
    validTimeDays: null,
  },
  isFormDetailReady: false,
  view: {
    stepRecords: null,
    isStepInfoVisible: true,
  },
};

const formTemplateSettingSlice = createSlice({
  name: 'formTemplateSetting',
  initialState,
  reducers: {
    setPages: (state, action) => {
      state.pages = action.payload;
      state.preview.copyPages = action.payload;
    },
    setFieldSettingVisible: (state, action) => {
      state.fieldSettingVisible = action.payload;
    },
    setFieldMenuVisible: (state, action) => {
      state.fieldMenuVisible = action.payload;
    },
    setDevice: (state, action) => {
      state.preview.device = action.payload;
    },
    setOrientation: (state, action) => {
      state.preview.orientation = action.payload;
    },
    setIsEditMode: (state, action) => {
      state.preview.isEditMode = action.payload;
    },
    setFormDetail: (state, action) => {
      state.formDetail = action.payload;
    },
    setFormDetailReady: (state, action) => {
      state.isFormDetailReady = action.payload;
    },
    setWorkflowValidation: (state, action) => {
      state.workflow.validation = action.payload;
    },
    setWorkflowNodes: (state, action) => {
      state.workflow.nodes = action.payload;
      state.workflow.steps = workflowStepMap(action.payload);
      state.workflow.buttons = workflowButtonMap(action.payload);
      state.preview.selectedStep = {
        id: state.workflow.steps?.[0]?.id ?? '',
        value: state.workflow.steps?.[0]?.id ?? '',
        label: state.workflow.steps?.[0]?.name ?? '',
      };
    },
    setWorkflowEdges: (state, action) => {
      state.workflow.edges = action.payload;
    },
    setSelectedStep: (state, action) => {
      state.preview.selectedStep = action.payload;
    },
    setSelectedTeamId: (state, action) => {
      state.preview.selectedTeamId = action.payload;
    },
    setIsStepInfoVisible: (state, action) => {
      state.view.isStepInfoVisible = action.payload;
    },
    setTempResource: (state, action) => {
      state.tempResource = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      formTemplateSettingApi.endpoints.getFormBody.matchFulfilled,
      (state, action) => {
        state.files = action.payload.files ?? [];
        state.photos = action.payload.photos ?? [];
        state.pages = action.payload.pages ?? [];
        state.preview.copyPages = action.payload.pages ?? [];
      },
    );
    builder.addMatcher(
      formTemplateSettingApi.endpoints.getFormWorkflow.matchFulfilled,
      (state, action) => {
        const nodes = action.payload.nodes ?? [
          {
            id: uuidv4(),
            type: 'step',
            position: { x: 0, y: 0 },
            data: cardStepDefaultData,
          },
        ];
        const edges = action.payload.edges ?? [];
        const steps = workflowStepMap(nodes);

        state.workflow.nodes = nodes;
        state.workflow.edges = edges;
        state.workflow.steps = steps;
        state.workflow.buttons = workflowButtonMap(nodes);
        state.preview.selectedStep = {
          id: state.workflow.steps?.[0]?.id ?? '',
          value: state.workflow.steps?.[0]?.id ?? '',
          label: state.workflow.steps?.[0]?.name ?? '',
        };
        state.view.stepRecords = workflowStepToStepRecords(steps);
        state.workflow.validation = validateFlowData(nodes, edges);
      },
    );
    builder.addMatcher(
      formTemplateSettingApi.endpoints.getFormDetail.matchFulfilled,
      (state, action) => {
        state.formDetail = action.payload;
        state.isFormDetailReady = true;
      },
    );
  },
});

export const {
  setPages,
  setFieldSettingVisible,
  setFieldMenuVisible,
  setDevice,
  setOrientation,
  setIsEditMode,
  setFormDetail,
  setFormDetailReady,
  setWorkflowNodes,
  setWorkflowEdges,
  setSelectedStep,
  setSelectedTeamId,
  setWorkflowValidation,
  setIsStepInfoVisible,
  setTempResource,
} = formTemplateSettingSlice.actions;
export default formTemplateSettingSlice.reducer;
