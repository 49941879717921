'use client';

import React, { useRef } from 'react';
import BaseVideo from '@/app/[lang]/(web)/_components/BaseVideo';
import EXTERNAL_LINKS from '@/utils/externalLink';
import { motion, useScroll, useTransform } from 'framer-motion';

const VideoWrapper = () => {
  const ref = useRef(null);

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'center center'], // [開始點, 結束點]
  });

  // 將滾動進度轉換為 scale 值
  const scale = useTransform(scrollYProgress, [0, 1], [0.8, 1]);

  return (
    <motion.div
      ref={ref}
      className="preserve-3d relative w-full will-change-transform"
      style={{
        scale,
        opacity: 1,
        transformStyle: 'preserve-3d',
        willChange: 'transform',
      }}
    >
      <BaseVideo
        videoId={EXTERNAL_LINKS.SOCIAL_MEDIA.YOUTUBE_VIDEO_ID}
        title="GoBuild Construction Management Software Demo"
        containerClassName="border-[12px] rounded-lg border-gray-200 w-full"
        aspectRatio="video"
        poster="maxresdefault"
      />
    </motion.div>
  );
};

export default VideoWrapper;
