export enum FileSystemNodeTypeEnum {
  Folder = 0,
  Template = 1,
}

export const formTemplateFolderConstants: Record<
  FileSystemNodeTypeEnum,
  {
    value: FileSystemNodeTypeEnum;
    label: string;
    icon: string;
  }
> = {
  [FileSystemNodeTypeEnum.Folder]: {
    value: FileSystemNodeTypeEnum.Folder,
    label: 'Folder',
    icon: 'FolderDefault',
  },
  [FileSystemNodeTypeEnum.Template]: {
    value: FileSystemNodeTypeEnum.Template,
    label: 'Template',
    icon: 'TemplateDefault',
  },
};
