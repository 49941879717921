import { createApi } from '@reduxjs/toolkit/query/react';
import {
  FormBody,
  FormDetail,
  FormWorkflow,
  OperateFormBodyRequest,
  OperateFormWorkflowRequest,
  UpdateFormDetailRequest,
} from '@/lib/form/formTemplateSetting/formTemplateSetting.interface';
import { axiosBaseQuery } from '@/lib/axiosBaseQuery';

export const formTemplateSettingApi = createApi({
  reducerPath: 'formTemplateSettingApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Form Builder', 'Form Workflow', 'Form Detail'],  // 加入 Form Detail
  endpoints: (builder) => ({
    getFormDetail: builder.query<FormDetail, any>({
      query: ({ formTemplateId }) => ({
        url: `/api/FormTemplateSetting/GetFormDetail/${formTemplateId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
      providesTags: ['Form Detail'],  // 加入 tag
    }),
    updateFormDetail: builder.mutation<true, UpdateFormDetailRequest>({
      query: (formTemplateSetting) => ({
        url: `/api/FormTemplateSetting/UpdateFormDetail`,
        method: 'PUT',
        data: formTemplateSetting,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          formTemplateSettingApi.util.invalidateTags([
            { type: 'Form Detail' as const },
          ]),
        );
      },
    }),
    getFormBody: builder.query<FormBody, any>({
      query: (templateId) => ({
        url: `/api/FormTemplateSetting/GetFormBody/${templateId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
      providesTags: ['Form Builder'],
    }),
    updateFormBody: builder.mutation<true, OperateFormBodyRequest>({
      query: (request) => ({
        url: `/api/FormTemplateSetting/UpdateFormBody`,
        method: 'PUT',
        data: request,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          formTemplateSettingApi.util.invalidateTags([
            { type: 'Form Builder' as const },
          ]),
        );
      },
    }),
    getFormWorkflow: builder.query<FormWorkflow, any>({
      query: (templateId) => ({
        url: `/api/FormTemplateSetting/GetFormWorkflow/${templateId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
      providesTags: ['Form Workflow'],
    }),
    updateFormWorkflow: builder.mutation<true, OperateFormWorkflowRequest>({
      query: (request) => ({
        url: `/api/FormTemplateSetting/UpdateFormWorkflow`,
        method: 'PUT',
        data: request,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          formTemplateSettingApi.util.invalidateTags([
            { type: 'Form Workflow' as const },
          ]),
        );
      },
    }),
  }),
});

export const {
  useGetFormDetailQuery,
  useUpdateFormDetailMutation,
  useGetFormBodyQuery,
  useUpdateFormBodyMutation,
  useGetFormWorkflowQuery,
  useUpdateFormWorkflowMutation,
} = formTemplateSettingApi;
