import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useMediaQuery } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import useCheckMobileAgent from '@/hooks/useCheckMobileAgent';
import BaseAvatar from '../base/BaseAvatar/BaseAvatar';

const CompanyModal = dynamic(
  () => import('@/components/adminConsole/CompanyModal'),
  { ssr: false },
);

function AuthShow({ profile }) {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isDesktopQuery = useMediaQuery('(max-width: 1024px)');

  const isMobile = useCheckMobileAgent();

  useEffect(() => {
    if (profile?.nameAbbreviation) {
      setIsLoading(false);
    }
  }, [profile]);

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      {isLoading ? null : (
        <button
          className="peer h-6 w-6 cursor-pointer lg:h-10 lg:w-10"
          onClick={handleShowModal}
          type="button"
        >
          <BaseAvatar
            name={profile.nameAbbreviation}
            bgColor={profile.abbreviationBgColor}
            photoUrl={profile.photoUrl}
            size={isDesktopQuery ? 24 : 40}
            className="h-6 w-6 lg:h-10 lg:w-10"
          />
        </button>
      )}
      {showModal && !isMobile && (
        <CompanyModal
          groups={profile?.groups}
          handleShowModal={handleShowModal}
          from="website"
        />
      )}
    </>
  );
}

export default AuthShow;
