import { cardStepDefaultData } from '@app/form/template/[formTemplateId]/_component/Workflow/CardData';
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

export const workflowButtonMap = (nodes: any[]) => {
  if (!nodes) return [];
  return nodes
    .filter((node) => node.type === 'button')
    .map((v) => ({
      id: v.id,
      type: v.type,
      name: v.data.name,
      color: v.data.color,
    }));
};

interface InitialState {
  workflow: {
    mode: 'collection';
    nodes: any[] | null;
    edges: any[] | null;
    validation: {
      isAllSendEmail: boolean;
      isAllButtonClose: boolean;
      isAllBackToPreviousClose: boolean;
      isAllCreateNewVersionClose: boolean;
      isAllClose: boolean;
    };
  };
}

const initialState: InitialState = {
  workflow: {
    mode: 'collection',
    nodes: [],
    edges: [],
    validation: {
      isAllSendEmail: false,
      isAllButtonClose: false,
      isAllBackToPreviousClose: false,
      isAllCreateNewVersionClose: false,
      isAllClose: false,
    },
  },
};

const collectionSettingSlice = createSlice({
  name: 'collectionSetting',
  initialState,
  reducers: {
    initialRootNode: (state) => {
      const root = {
        id: uuidv4(),
        type: 'step',
        position: { x: 0, y: 0 },
        data: {
          ...cardStepDefaultData,
          stepName: 'Step 1',
        },
      };
      state.workflow.edges = [];
      state.workflow.nodes = [root];
      state.workflow.validation = {
        isAllSendEmail: false,
        isAllButtonClose: false,
        isAllBackToPreviousClose: false,
        isAllCreateNewVersionClose: false,
        isAllClose: false,
      };
    },
    setWorkflowValidation: (state, action) => {
      state.workflow.validation = action.payload;
    },
    setWorkflowNodes: (state, action) => {
      state.workflow.nodes = action.payload;
    },
    setWorkflowEdges: (state, action) => {
      state.workflow.edges = action.payload;
    },
  },
});

export const {
  initialRootNode,
  setWorkflowNodes,
  setWorkflowEdges,
  setWorkflowValidation,
} = collectionSettingSlice.actions;
export default collectionSettingSlice.reducer;
