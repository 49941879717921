'use client';

import React, { useState } from 'react';
import PlanCard from '@/app/[lang]/(web)/_components/PlanCard';
import PlanToggle from '@/app/[lang]/(web)/_components/PlanToggle';
import { useAppSelector } from '@/lib/hooks';
import { RootState } from '@/lib/store';
import { createSelector } from '@reduxjs/toolkit';

interface ClientPlanWrapperProps {
  planData: any;
  t: any;
  lang: string;
}

const selectUserGroups = (state: RootState) => state.user.profile.groups;
const selectFilteredGroups = createSelector([selectUserGroups], (groups) =>
  groups.filter((v) => [1, 2].includes(v.roleId)),
);

const ClientPlanWrapper = ({ planData, t, lang }: ClientPlanWrapperProps) => {
  const [isYearly, setIsYearly] = useState(true);
  const group = useAppSelector(selectFilteredGroups);

  return (
    <div>
      <PlanToggle isYearly={isYearly} onChange={setIsYearly} t={t} />
      <div className="grid grid-cols-1 gap-4 pt-4 md:grid-cols-2 lg:grid-cols-3">
        {planData.map((plan) => (
          <PlanCard
            key={plan.title}
            plan={plan}
            isYearly={isYearly}
            t={t}
            group={group}
            lang={lang}
          />
        ))}
      </div>
    </div>
  );
};

export default ClientPlanWrapper;
