import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  isSmartAppBannerOpen: boolean;
}

const initialState: InitialState = {
  isSmartAppBannerOpen: true,
};

const webSlice = createSlice({
  name: 'web',
  initialState,
  reducers: {
    setIsSmartAppBannerOpen: (state, action) => {
      state.isSmartAppBannerOpen = action.payload;
    },
  },
});

export const { setIsSmartAppBannerOpen } = webSlice.actions;
export default webSlice.reducer;
