'use client';

import { useRouter } from 'next/navigation';
import React, { useState } from 'react';

const ClientEmailAction = ({ t }: { t: any }) => {
  const router = useRouter();
  const [email, setEmail] = useState('');

  const handleClick = () => {
    if (email) {
      router.push(`/signup?email=${encodeURIComponent(email)}`);
    }
  };

  return (
    <div className="flex w-full max-w-[582px] flex-col gap-2 rounded-2xl border-[2px] border-b-[6px] border-primary-500 bg-white p-2 lg:flex-row">
      <input
        type="email"
        placeholder={t.homeEmailSectionPlaceholder}
        className="w-full px-2 py-[14px]"
        onChange={(e) => setEmail(e.target.value)}
      />
      <button
        type="button"
        className="w-full rounded-[10px] bg-primary-500 py-[14px] text-white lg:w-[144px]"
        onClick={handleClick}
      >
        {t.homeEmailSectionButtonText}
      </button>
    </div>
  );
};

export default ClientEmailAction;
