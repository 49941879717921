import Image from 'next/image';
import React from 'react';
import { AvatarSize } from './interface';

export interface BaseAvatarProps {
  size: AvatarSize;
  photoUrl: string;
  fullName: string;
  abbreviationBgColor: string;
  nameAbbreviation: string;
  projectRoleId?: number;
  rounded?: boolean;
}

const textSizeObj = {
  72: 'typo-paragraph-xxl',
  48: 'typo-paragraph-md',
  40: 'typo-paragraph-sm',
  32: 'text-[12px] leading-6',
  24: 'text-[9px] leading-[12.6px]',
  20: 'text-[7px] leading-[9.8px]',
  16: 'text-[6px] leading-[8.4px]',
};

const BaseAvatar: React.FC<BaseAvatarProps> = ({
  size = 16,
  photoUrl,
  fullName,
  abbreviationBgColor,
  nameAbbreviation,
  projectRoleId,
  rounded = true,
}) => {
  const roundedClass = rounded ? 'rounded-full' : '';
  const getRingClass = (projectRoleId: number | undefined) => {
    if (!projectRoleId) {
      return 'border-1 border-gray-300';
    }
    if (projectRoleId === 5) {
      return 'ring-complementary-malibu-900 ring-1 ring-offset-1';
    }
    return 'ring-1 ring-secondary-300 ring-offset-1';
  };
  const ringClass = getRingClass(projectRoleId);

  const nameAbbreviationClass = textSizeObj[size];

  if (photoUrl) {
    return (
      // outline-dashed outline-black-dividers border-2 border-white
      <div
        className={`${ringClass} relative z-[1] overflow-hidden ${roundedClass} bg-gray-100`}
        style={{ width: size, height: size }}
      >
        <Image
          src={photoUrl}
          alt={`${fullName} Avatar` || 'Avatar'}
          width={size}
          height={size}
          className="h-full w-full object-cover"
        />
      </div>
    );
  }

  if (abbreviationBgColor) {
    return (
      <div
        className={`${ringClass} z-[1] flex items-center justify-center ${roundedClass} bg-gray-100 text-[6px] uppercase leading-[8.4px] text-white`}
        style={{
          backgroundColor: abbreviationBgColor,
          width: size,
          height: size,
        }}
      >
        <p className={`${nameAbbreviationClass} text-nowrap`}>
          {nameAbbreviation}
        </p>
      </div>
    );
  }

  if (!photoUrl && !abbreviationBgColor) {
    return (
      <div
        className={`relative z-[1] overflow-hidden ${roundedClass} border-2 border-dashed bg-gray-100`}
        style={{ width: size, height: size }}
       />
    );
  }
};

export default BaseAvatar;
