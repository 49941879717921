import BaseVideo from '@/app/[lang]/(web)/_components/BaseVideo';
import { BlogData } from '@/lib/blogs/interface';
import Link from 'next/link';
import React from 'react';
import ArrowDownSimple from '@/assets/icons/base/arrow/ArrowDownSimple.svg';
import EXTERNAL_LINKS from '@/utils/externalLink';
import { getHref } from '@/app/[lang]/(web)/_components/FooterLinkGroup';

const NavItem = ({ text, linkList, id, blogData, t, lang }) => {
  const planItems = [
    {
      id: 1,
      text: 'Free',
      href: `/pricing`,
    },
    {
      id: 2,
      text: 'Pro',
      href: `/pricing`,
    },
    {
      id: 3,
      text: 'ProPlus',
      href: `/pricing`,
    },
  ];
  return (
    <li className="group relative">
      {/* 導航項目 */}
      <button
        type="button"
        className="typo-label-sm flex items-center gap-2 text-nowrap px-2 py-[10px]"
      >
        {t[text] ?? text}
        <ArrowDownSimple width={16} height={16} media="svg" />
      </button>

      {/* Mega Menu */}
      <div
        className={`invisible absolute left-0 top-12 z-50 ${linkList.length <= 3 ? 'w-[560px]' : 'w-[720px]'} translate-y-2 rounded-xl opacity-0 shadow-[0px_1px_16px_0px_#00000014] transition-all duration-300 group-hover:visible group-hover:translate-y-0 group-hover:opacity-100`}
      >
        {/* Menu 內容 */}
        <div className="relative flex w-full rounded-t-lg bg-white">
          <div
            className={`grid h-fit flex-1 px-2 py-5 ${linkList.length <= 3 ? 'grid-cols-1' : 'grid-cols-[auto_auto]'}`}
          >
            {linkList.map((item) => (
              <Link
                key={item.id}
                href={getHref(item.href, lang)}
                {...(item.href.startsWith('http') && {
                  target: '_blank',
                  rel: 'noopener noreferrer',
                })}
                className="flex h-fit flex-col justify-center gap-[2px] rounded-lg px-2 py-4 hover:bg-gray-100"
              >
                <span className="typo-label-sm text-nowrap text-black">
                  {t[item.text] ?? item.text}
                </span>
                {item.description && (
                  <span className="text-sm text-gray-600">
                    {t[item.description] ?? item.description}
                  </span>
                )}
              </Link>
            ))}
          </div>
          <div className="rounded-tr-lg bg-gray-200">
            {id === 1 && (
              <div className="flex h-full w-[208px] items-center justify-center">
                <BaseVideo
                  videoId="qOdwQAmiO00"
                  title="GoBuild Construction Management Software Demo"
                  containerClassName="border-[12px] rounded-lg border-gray-200 w-full"
                  aspectRatio="video"
                  poster="maxresdefault"
                />
              </div>
            )}
            {id === 2 && (
              <div className="w-[240px] bg-gray-200 px-2 py-5">
                <p className="typo-label-xs mb-2 px-2 uppercase text-gray-600">
                  {t.navPlan}
                </p>
                <div className="space-y-2">
                  {planItems.map((v) => (
                    <Link
                      key={`${v.id}-${v.text}`}
                      href={`/${lang}${v.href}`}
                      className="block rounded-lg px-2 py-4 hover:bg-gray-100"
                    >
                      <span className="typo-label-sm">
                        {t[v.text] ?? v.text}
                      </span>
                    </Link>
                  ))}
                </div>
              </div>
            )}
            {id === 3 && (
              <div className="w-[400px]">
                <div className="bg-gray-200 px-2 py-5">
                  <p className="typo-label-xs mb-2 px-2 uppercase text-gray-600">
                    {t.navBlog}
                  </p>
                  <div className="space-y-2">
                    {blogData.data.map((blog) => (
                      <Link
                        key={blog.blogId}
                        href={`/${lang}/blog/all-articles/${blog.blogId}-${blog.articleName}`}
                        className="block rounded-lg p-2 hover:bg-gray-100"
                      >
                        <span className="typo-label-sm line-clamp-1">
                          {blog.articleName}
                        </span>
                        <span className="typo-paragraph-xs line-clamp-1 text-gray-600">
                          {blog.articleDescription}
                        </span>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center gap-4 rounded-b-lg bg-black px-5 py-2">
          <Link
            href={EXTERNAL_LINKS.SERVICE.CONTACT_SALES}
            target="_blank"
            rel="noopener noreferrer"
            className="typo-label-xs px-2 py-1.5 text-white"
          >
            {t.navContactSales}
          </Link>
          <span className="h-[20px] w-px bg-gray-500" />
          <Link
            href={`/${lang}/download`}
            target="_blank"
            rel="noopener noreferrer"
            className="typo-label-xs px-2 py-1.5 text-white"
          >
            {t.navDownloadApp}
          </Link>
        </div>
      </div>
    </li>
  );
};

interface DesktopNavProps {
  navList: any[];
  blogData: BlogData;
  t: any;
  lang: string;
}

const DesktopNav = ({ navList, blogData, t, lang }: DesktopNavProps) => {
  return (
    <nav className="relative">
      <ul className="flex items-center gap-4">
        {navList.map((item) => (
          <NavItem
            key={item.id}
            {...item}
            blogData={blogData}
            t={t}
            lang={lang}
          />
        ))}
        <li>
          <Link
            href={`/${lang}/pricing`}
            className="typo-label-sm text-nowrap px-2 py-[10px]"
          >
            {t.navPricing}
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default DesktopNav;
