import {
  CardButtonData,
  CardStepData,
  CardSendEmailData,
  CardBackToPreviousData,
  CardCreateNewVersionData,
  CardCloseData,
} from '@app/form/template/[formTemplateId]/_component/Workflow/CardDataInterface';

export const cardButtonDefaultData: CardButtonData = {
  name: 'Submit',
  color: '#0029A5',
  order: 0,
};
export const cardStepDefaultData: CardStepData = {
  stepName: 'Untitled Step',
  buttonsAlignment: 'right',
  teamIds: [],
  isLocationBasedSubmission: false,
  geoFenceRadiusMeters: 0,
};
export const cardSendEmailDefaultData: CardSendEmailData = {
  subject: '',
  content: '',
  teamIds: [],
  emails: [],
};
export const cardBackToPreviousDefaultData: CardBackToPreviousData = {
  isClearRecord: false,
};
export const cardCreateNewVersionDefaultData: CardCreateNewVersionData = {
  startFromStepId: [],
  isClearRecord: false,
};
export const cardCloseDefaultData: CardCloseData = {
  isCreateNewVersion: false,
  isClearRecord: false,
};
