import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@/lib/axiosBaseQuery';
import {
  OperateSubmissionFormBodyRequest,
  OperateSubmissionFormWorkflowRequest,
  SubmissionFormBody,
  SubmissionFormDetail,
  UpdateSubmissionFormDetailRequest,
} from '@/lib/submissionTemplates/submissionTemplateSetting.interface';

export const submissionTemplateSettingApi = createApi({
  reducerPath: 'submissionTemplateSettingApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Submission Form Workflow'],
  endpoints: (builder) => ({
    getSubmissionFormBody: builder.query<SubmissionFormBody, any>({
      query: (submissionTemplateId) => ({
        url: `/api/SubmissionTemplateSetting/GetFormBody/${submissionTemplateId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
    }),
    getSubmissionFormDetail: builder.query<SubmissionFormDetail, any>({
      query: (submissionTemplateId) => ({
        url: `/api/SubmissionTemplateSetting/GetFormDetail/${submissionTemplateId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
    }),
    getSubmissionFormDetailSubForm: builder.query<SubmissionFormDetail, any>({
      query: (submissionTemplateId) => ({
        url: `/api/SubmissionTemplateSetting/GetFormDetail/${submissionTemplateId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
    }),
    updateSubmissionFormBody: builder.mutation<
      true,
      OperateSubmissionFormBodyRequest
    >({
      query: (data) => ({
        url: `/api/SubmissionTemplateSetting/UpdateFormBody`,
        method: 'PUT',
        data,
      }),
    }),
    getSubmissionWorkflow: builder.query<any, any>({
      query: (templateId) => ({
        url: `/api/SubmissionTemplateSetting/GetFormWorkflow/${templateId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
    }),
    getSubmissionWorkflowSubForm: builder.query<any, any>({
      query: (templateId) => ({
        url: `/api/SubmissionTemplateSetting/GetFormWorkflow/${templateId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
    }),
    updateFormWorkflow: builder.mutation<
      true,
      OperateSubmissionFormWorkflowRequest
    >({
      query: (request) => ({
        url: `/api/SubmissionTemplateSetting/UpdateFormWorkflow`,
        method: 'PUT',
        data: request,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          submissionTemplateSettingApi.util.invalidateTags([
            { type: 'Submission Form Workflow' as const },
          ]),
        );
      },
    }),
    updateFormDetail: builder.mutation<any, UpdateSubmissionFormDetailRequest>({
      query: (formTemplateSetting) => ({
        url: `/api/SubmissionTemplateSetting/UpdateFormDetail`,
        method: 'PUT',
        data: formTemplateSetting,
      }),
    }),
  }),
});

export const {
  useGetSubmissionFormBodyQuery,
  useGetSubmissionFormDetailQuery,
  useGetSubmissionFormDetailSubFormQuery,
  useUpdateSubmissionFormBodyMutation,
  useGetSubmissionWorkflowQuery,
  useGetSubmissionWorkflowSubFormQuery,

  useUpdateFormWorkflowMutation,
  useUpdateFormDetailMutation,
} = submissionTemplateSettingApi;
