import {
  FormCollectionQueryFilter,
  FormCollectionViewModel,
  FormCollectionWorkflow,
  FormCollectionWorkflowResponse,
} from '@/lib/form/formCollection.interface';
import { ApiResponse, ByPageData } from '@/types/interface';
import { AxiosResponse } from 'axios';
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosBaseQuery';

export const formCollectionAPI = createApi({
  reducerPath: 'formCollectionAPI',
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
  tagTypes: ['FormCollection'],
});

export const formCollectionApi = formCollectionAPI.injectEndpoints({
  endpoints: (builder) => ({
    getFormCollection: builder.query<
      ByPageData<FormCollectionViewModel>,
      FormCollectionQueryFilter
    >({
      query: (params) => ({
        url: `/api/FormCollection`,
        method: 'GET',
        params,
      }),
      transformResponse: (
        response: AxiosResponse<
          ApiResponse<ByPageData<FormCollectionViewModel>>
        >,
      ) => {
        return response.data.data;
      },
      providesTags: ['FormCollection'],
      merge: (currentCache, newItems, { arg }) => {
        if (!currentCache || arg.pageNumber === 1) {
          return newItems;
        }

        const currentData = currentCache.data || [];
        const newData = newItems.data || [];

        const mergedData = [...currentData, ...newData];
        const uniqueData = Array.from(
          new Map(
            mergedData.map((item) => [item.formCollectionId, item]),
          ).values(),
        );

        return {
          ...newItems,
          data: uniqueData,
          totalPageCount: newItems.totalPageCount,
          totalCount: newItems.totalCount,
          pageNumber: newItems.pageNumber,
          pageCount: newItems.pageCount,
        };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const { pageNumber, pageCount, ...rest } = queryArgs;
        return rest;
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        if (!currentArg || !previousArg) return true;

        const {
          pageNumber: currentPage,
          pageCount: currentPageCount,
          ...currentRest
        } = currentArg;
        const {
          pageNumber: previousPage,
          pageCount: previousPageCount,
          ...previousRest
        } = previousArg;

        return JSON.stringify(currentRest) !== JSON.stringify(previousRest);
      },
    }),
    // 有些地方用無限滾動，這個是分頁的
    getFormCollectionByPage: builder.query<
      ByPageData<FormCollectionViewModel>,
      FormCollectionQueryFilter
    >({
      query: (params) => ({
        url: `/api/FormCollection`,
        method: 'GET',
        params,
      }),
      transformResponse: (
        response: AxiosResponse<
          ApiResponse<ByPageData<FormCollectionViewModel>>
        >,
      ) => {
        return response.data.data;
      },
      providesTags: ['FormCollection'],
    }),
    getFormCollectionWorkflow: builder.query<
      FormCollectionWorkflowResponse,
      { formCollectionId: number }
    >({
      query: ({ formCollectionId }) => ({
        url: `/api/FormCollection/Workflow/${formCollectionId}`,
        method: 'GET',
      }),
      transformResponse: (
        response: AxiosResponse<ApiResponse<FormCollectionWorkflowResponse>>,
      ) => {
        return response.data.data;
      },
      keepUnusedDataFor: 30, // 30 seconds cache
    }),
    createFormCollection: builder.mutation<
      any,
      FormData // 參考 FormCollectionCreateRequestFormBody
    >({
      query: (data) => ({
        url: '/api/FormCollection',
        method: 'POST',
        data,
      }),
      transformResponse: (response: AxiosResponse<ApiResponse<number>>) => {
        return response.data.data;
      },
      invalidatesTags: ['FormCollection'],
    }),
    duplicateFormCollection: builder.mutation<any, number>({
      query: (formCollectionId) => ({
        url: `/api/FormCollection/${formCollectionId}/Duplicate`,
        method: 'POST',
      }),
      transformResponse: (response: AxiosResponse<ApiResponse<number>>) => {
        return response.data.data;
      },
      invalidatesTags: ['FormCollection'],
    }),
    updateFormCollection: builder.mutation<
      any,
      FormData // 參考 FormCollectionUpdateRequestFormBody
    >({
      query: (data) => ({
        url: `/api/FormCollection/${data.get('formCollectionId')}`,
        method: 'PUT',
        data,
      }),
      transformResponse: (response: AxiosResponse<ApiResponse<boolean>>) => {
        return response.data.data;
      },
      invalidatesTags: ['FormCollection'],
    }),
    deleteFormCollection: builder.mutation<any, number>({
      query: (formCollectionId) => ({
        url: `/api/FormCollection/${formCollectionId}`,
        method: 'DELETE',
      }),
      transformResponse: (response: AxiosResponse<ApiResponse<boolean>>) => {
        return response.data.data;
      },
      invalidatesTags: ['FormCollection'],
    }),
  }),
});

export const {
  useGetFormCollectionQuery,
  useGetFormCollectionByPageQuery,
  useGetFormCollectionWorkflowQuery, // 改用 Query hook
  useCreateFormCollectionMutation,
  useDuplicateFormCollectionMutation,
  useUpdateFormCollectionMutation,
  useDeleteFormCollectionMutation,
} = formCollectionApi;
