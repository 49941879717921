import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@/lib/axiosBaseQuery';
import { PhotoBrief } from '@/lib/form/formCollection.interface';
import type {
  GetPhotosRequest,
  AssignFormCollectionPhotosRequest,
  UploadResourcesRequest,
  UploadResourcesResponse,
  DeleteResourcesRequest,
} from './formFIles.interface';

export const formFilesApi = createApi({
  reducerPath: 'formFilesApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['FormPhoto', 'FormFile'],
  endpoints: (builder) => ({
    getPhotos: builder.query<PhotoBrief[], GetPhotosRequest>({
      query: (request) => ({
        url: '/api/FormFiles/Photos',
        method: 'GET',
        params: {
          ...request,
          photoIds: request.photoIds.join(','),
        },
      }),
      transformResponse: (response) => response.data.data,
      providesTags: (result, error, { photoIds }) => [
        ...(photoIds?.map((photoId) => ({
          type: 'FormPhoto' as const,
          id: photoId,
        })) || []),
        'FormPhoto',
      ],
    }),

    assignFormCollectionPhotos: builder.mutation<
      number,
      AssignFormCollectionPhotosRequest
    >({
      query: (request) => ({
        url: '/api/FormFiles/AssignFormCollectionPhotos',
        method: 'POST',
        data: request,
      }),
      invalidatesTags: ['FormPhoto'],
      transformResponse: (response) => response.data.data,
    }),

    uploadResources: builder.mutation<
      UploadResourcesResponse,
      UploadResourcesRequest
    >({
      query: (request) => {
        const formData = new FormData();
        Object.entries(request).forEach(([key, value]) => {
          if (value !== undefined) {
            formData.append(key, value);
          }
        });
        return {
          url: '/api/FormFiles/UploadResources',
          method: 'POST',
          data: formData,
        };
      },
      transformResponse: (response) => response.data.data,
    }),

    deleteResources: builder.mutation<boolean, DeleteResourcesRequest>({
      query: (request) => ({
        url: '/api/FormFiles/DeleteResources',
        method: 'DELETE',
        data: request,
      }),
      transformResponse: (response) => response.data.data,
      invalidatesTags: (result, error, { fileIds }) => [
        ...(fileIds?.map((fileId) => ({
          type: 'FormFile' as const,
          id: fileId,
        })) || []),
      ],
    }),
  }),
});

export const {
  useGetPhotosQuery,
  useAssignFormCollectionPhotosMutation,
  useUploadResourcesMutation,
  useDeleteResourcesMutation,
} = formFilesApi;
