import { createApi } from '@reduxjs/toolkit/query/react';
import {
  AssignTemplatesRequest,
  CreateSubmissionFolderRequest,
  DeleteRequest,
  DuplicateSubmissionToProjectRequest,
  GetSubmissionFolderContentsRequest,
  GetSubmissionFolderContentsResponse,
  GetSubmissionTemplatesByPageFilter,
  GetSubmissionTemplatesByPageFilterRequest,
  GetSubmissionTemplatesFolderByPageFilter,
  RenameContentRequest,
  RestoreRequest,
  SubmissionTemplateFolder,
} from '@/lib/submissionTemplates/submissionTemplates.interface';
import { AxiosResponse } from 'axios';
import { ApiResponse, ByPageData } from '@/types/interface';
import { axiosBaseQuery } from '../axiosBaseQuery';

export const submissionTemplatesApi = createApi({
  reducerPath: 'submissionTemplatesApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['SubmissionTemplates'],
  endpoints: (builder) => ({
    getSubmissionTemplateFoldersByPageFilter: builder.query<
      GetSubmissionTemplatesFolderByPageFilter,
      GetSubmissionTemplatesByPageFilterRequest
    >({
      query: (params) => ({
        url: '/api/SubmissionTemplates/GetSubmissionTemplateFoldersByPageFilter',
        method: 'GET',
        params,
      }),
      transformResponse: (response: any) => {
        return response.data.data;
      },
      providesTags: ['SubmissionTemplates'],
    }),

    getSubmissionTemplatesByPageFilter: builder.query<
      GetSubmissionTemplatesByPageFilter,
      GetSubmissionTemplatesByPageFilterRequest
    >({
      query: (params) => ({
        url: '/api/SubmissionTemplates/GetSubmissionTemplatesByPageFilter',
        method: 'GET',
        params,
      }),
      transformResponse: (response) => {
        return response.data.data;
      },
      providesTags: ['SubmissionTemplates'],
    }),

    getSubmissionFolderContents: builder.query<
      GetSubmissionFolderContentsResponse,
      GetSubmissionFolderContentsRequest
    >({
      query: (params) => ({
        url: '/api/SubmissionTemplates/GetSubmissionFolderContents',
        method: 'GET',
        params,
      }),
      transformResponse: (response: any) => {
        return response.data.data;
      },
      providesTags: ['SubmissionTemplates'],
    }),

    getSubmissionTrashed: builder.query<any, any>({
      query: (params) => ({
        url: `/api/SubmissionTemplates/GetSubmissionTrashed/${params.projectId}`,
        method: 'GET',
      }),
      transformResponse: (response: any) => {
        return response.data.data;
      },
      providesTags: ['SubmissionTemplates'],
    }),

    getSubmissionFolders: builder.query<any, any>({
      query: (params) => ({
        url: `/api/SubmissionTemplates/GetSubmissionFolders/${params.projectId}`,
        method: 'GET',
      }),
      transformResponse: (response: any) => {
        return response.data.data;
      },
      providesTags: ['SubmissionTemplates'],
    }),

    assignTemplates: builder.mutation<any, AssignTemplatesRequest>({
      query: (params) => ({
        url: '/api/SubmissionTemplates/AssignTemplates',
        method: 'POST',
        data: params,
      }),
      transformResponse: (response: any) => {
        return response.data.data;
      },
      invalidatesTags: ['SubmissionTemplates'],
    }),

    createSubmissionFolder: builder.mutation<
      any,
      CreateSubmissionFolderRequest
    >({
      query: (params) => ({
        url: '/api/SubmissionTemplates/CreateSubmissionFolder',
        method: 'POST',
        data: params,
      }),
      transformResponse: (response: any) => {
        return response.data.data;
      },
      invalidatesTags: ['SubmissionTemplates'],
    }),

    restore: builder.mutation<any, RestoreRequest>({
      query: (params) => ({
        url: '/api/SubmissionTemplates/Restore',
        method: 'POST',
        data: params,
      }),
      transformResponse: (response: any) => {
        return response.data.data;
      },
      invalidatesTags: ['SubmissionTemplates'],
    }),

    duplicateSubmissionToProject: builder.mutation<
      any,
      DuplicateSubmissionToProjectRequest
    >({
      query: (params) => ({
        url: '/api/SubmissionTemplates/DuplicateToProject',
        method: 'POST',
        data: params,
      }),
      transformResponse: (response: any) => {
        return response.data.data;
      },
      invalidatesTags: ['SubmissionTemplates'],
    }),

    renameContent: builder.mutation<any, RenameContentRequest>({
      query: (data) => ({
        url: `/api/SubmissionTemplates/RenameContent`,
        method: 'PUT',
        data,
      }),
      transformResponse: (response: any) => {
        return response.data.data;
      },
      invalidatesTags: ['SubmissionTemplates'],
    }),

    moveToTrash: builder.mutation<any, DeleteRequest>({
      query: (params) => ({
        url: '/api/SubmissionTemplates/MoveToTrash',
        method: 'DELETE',
        data: params,
      }),
      transformResponse: (response: any) => {
        return response.data.data;
      },
      invalidatesTags: ['SubmissionTemplates'],
    }),

    deleteForever: builder.mutation<any, DeleteRequest>({
      query: (params) => ({
        url: '/api/SubmissionTemplates/DeleteForever',
        method: 'DELETE',
        data: params,
      }),
      transformResponse: (response: any) => {
        return response.data.data;
      },
      invalidatesTags: ['SubmissionTemplates'],
    }),

    clearTrashed: builder.mutation<any, any>({
      query: (params) => ({
        url: `/api/SubmissionTemplates/ClearTrashed/${params.projectId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetSubmissionTemplateFoldersByPageFilterQuery,
  useGetSubmissionTemplatesByPageFilterQuery,
  useGetSubmissionFolderContentsQuery,
  useAssignTemplatesMutation,
  useGetSubmissionTrashedQuery,
  useCreateSubmissionFolderMutation,
  useGetSubmissionFoldersQuery,
  useRenameContentMutation,
  useMoveToTrashMutation,
  useDeleteForeverMutation,
  useClearTrashedMutation,
  useRestoreMutation,
  useDuplicateSubmissionToProjectMutation,
} = submissionTemplatesApi;
