import(/* webpackMode: "eager" */ "/app/packages/website/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/packages/website/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lang]/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"subsets\":[\"latin\"],\"adjustFontFallback\":true,\"display\":\"swap\",\"preload\":true}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/app/packages/website/node_modules/leaflet/dist/leaflet.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/packages/website/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/packages/website/src/assets/styles/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/website/src/components/paddle/paddleLoader.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/website/src/lib/providers.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/website/src/utils/LocalizationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/website/src/utils/ThemeProviders.jsx");
