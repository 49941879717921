'use client';

import React from 'react';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

interface BaseVideoProps {
  videoId: string;
  title: string;
  className?: string;
  containerClassName?: string;
  aspectRatio?: 'video' | 'square' | '16/9' | '4/3';
  poster?: 'hqdefault' | 'maxresdefault' | 'sddefault' | 'mqdefault';
  onIframeAdded?: () => void;
  noCookie?: boolean;
}

const BaseVideo = ({
  videoId,
  title,
  className = '',
  containerClassName = '',
  aspectRatio = 'video',
  poster = 'maxresdefault',
  onIframeAdded,
  noCookie = true,
}: BaseVideoProps) => {
  const aspectRatioClasses = {
    video: 'aspect-video',
    square: 'aspect-square',
    '16/9': 'aspect-[16/9]',
    '4/3': 'aspect-[4/3]',
  };

  return (
    <div
      className={`relative ${aspectRatioClasses[aspectRatio]} ${containerClassName}`}
      // 提供更好的 SEO 結構化資料
      itemScope
      itemType="https://schema.org/VideoObject"
    >
      <meta itemProp="name" content={title} />
      <meta
        itemProp="embedUrl"
        content={`https://www.youtube.com/embed/${videoId}`}
      />
      <meta
        itemProp="thumbnailUrl"
        content={`https://i.ytimg.com/vi/${videoId}/${poster}.jpg`}
      />

      <LiteYouTubeEmbed
        id={videoId}
        title={title}
        poster={poster}
        webp // 使用 WebP 格式提升效能
        noCookie={noCookie} // GDPR 合規
        params="modestbranding=1&rel=0" // 自訂 YouTube 參數
        onIframeAdded={onIframeAdded}
        adNetwork={false} // 禁用廣告以提升效能
        muted
      />

      {/* 無障礙支援 */}
      <a
        href={`https://www.youtube.com/watch?v=${videoId}`}
        className="sr-only focus:not-sr-only focus:absolute focus:z-10 focus:bg-white focus:p-2 focus:text-black"
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`在 YouTube 觀看${title}`}
      >
        Watch on YouTube {title}
      </a>
    </div>
  );
};

export default BaseVideo;
