'use client';

import dynamic from 'next/dynamic';
import Link from 'next/link';
import useGoRouter from '@/hooks/useGoRouter';

const LanguageSwitcher = dynamic(
  () => import('@/components/UI/LanguageSwitcher'),
  { ssr: false },
);

function RightBtnArea({ t }) {
  const { goRouterPay, goRouterConsole, loginArea, startArea } = useGoRouter();

  const loginButtonStyle = {
    backgroundColor: loginArea === 'navUpgrade' ? '#14E869' : '#FEFEFE',
    color: '#262D42',
    borderWidth: loginArea !== 'navUpgrade' ? '1px' : '0',
    borderStyle: loginArea !== 'navUpgrade' ? 'solid' : 'none',
    borderColor: loginArea !== 'navUpgrade' ? '#F0F0F2' : 'transparent',
  } as const;

  const startButtonStyle = {
    backgroundColor: startArea === 'navLaunchButton' ? '#FFFFFF' : '#0029A5',
    color: startArea === 'navLaunchButton' ? '#262D42' : '#FCFCFC',
    borderWidth: '0',
    borderStyle: 'none',
  } as const;

  return (
    <>
      <LanguageSwitcher />

      <Link
        href={goRouterPay}
        className="typo-label-sm hidden rounded-[26px] px-4 py-[10px] text-center lg:block"
        style={loginButtonStyle}
      >
        {t[loginArea]}
      </Link>
      <Link
        href={goRouterConsole}
        className="typo-label-sm hidden whitespace-nowrap rounded-[26px] px-4 py-[10px] text-center lg:block"
        style={startButtonStyle}
      >
        {t[startArea]}
      </Link>
    </>
  );
}

export default RightBtnArea;
