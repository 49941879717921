'use client';

import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { BaseIcon } from '@/components/base/BaseIcon/BaseIcon';
import Link from 'next/link';

interface Tab {
  id: number;
  title: string;
  answers: string[];
  link?: string;
}

const tabs: Tab[] = [
  {
    id: 1,
    title: 'homeQuestionOne',
    answers: ['homeQuestionOneContent'],
    link: '/pricing',
  },
  {
    id: 2,
    title: 'homeQuestionTwo',
    answers: ['homeQuestionTwoContent', 'homeQuestionTwoContent2'],
  },
  {
    id: 3,
    title: 'homeQuestionThree',
    answers: ['homeQuestionThreeContent', 'homeQuestionThreeContent2'],
    link: 'https://www.youtube.com/watch?v=IfFL40Jai98',
  },
  {
    id: 4,
    title: 'homeQuestionFour',
    answers: ['homeQuestionFourContent'],
  },
];
const FAQsSection = ({ t, title }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleClick = async (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const renderLink = (link: string | undefined, content: React.ReactNode) => {
    if (!link) return content;

    // 檢查是否為外部連結
    const isExternalLink = link.startsWith('https');

    if (isExternalLink) {
      return (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary-600 hover:underline"
        >
          {content}.
        </a>
      );
    }

    return (
      <Link href={link} className="text-primary-600 hover:underline">
        {content}.
      </Link>
    );
  };

  return (
    <section className="flex flex-col gap-5 rounded-2xl border-[6px] border-white bg-white/60 px-5 py-10 backdrop-blur-[50px]">
      <h2 className="typo-heading-md p-2 lg:p-0 lg:text-center lg:text-5xl lg:font-bold">
        {title}
      </h2>
      <div>
        {tabs.map((tab, index) => (
          <motion.div
            key={index}
            className={`overflow-hidden border-b`}
            onClick={() => handleClick(index)}
          >
            <button
              type="button"
              className="flex w-full items-center gap-6 px-2 py-4 lg:typo-heading-sm lg:px-4 lg:py-6"
            >
              <p className="flex-1 text-start">{t[tab.title]}</p>
              <BaseIcon
                name="ArrowDownSimple"
                size={24}
                color="#595F6F"
                className={`${activeIndex === index ? 'rotate-180' : ''} transition-all delay-[140ms] duration-300 ease-in-out`}
              />
            </button>
            <AnimatePresence mode="sync">
              {activeIndex === index && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{
                    duration: 0.3,
                    ease: 'easeInOut',
                    delay: 0.14,
                  }}
                  className="px-2 lg:px-4"
                >
                  {tab.answers.map((answer, i) => (
                    <p
                      key={answer}
                      className="typo-paragraph-xs pb-2 text-gray-800 lg:typo-paragraph-sm"
                    >
                      {t[answer]}
                      {tab.link &&
                        i === tab.answers.length - 1 &&
                        renderLink(tab.link, t.homeHere)}
                    </p>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default FAQsSection;
