import { configureStore, combineReducers } from '@reduxjs/toolkit';

import userSlice from '@/lib/users/userSlice';
import adminConsoleMemberSlice from '@/lib/adminConsole/member/memberSlice';
import accountSlice from '@/lib/account/accountSlice';
import paymentSlice from '@/lib/payment/paymentSlice';
import dialogsSlice from '@/lib/dialogs/dialogsSlice';
import taskListSlice from '@/lib/taskList/taskListSlice';
import appSlice from '@/lib/app/appSlice';
import groupSlice from '@/lib/group/groupSlice';
import clockSlice from '@/lib/clock/clockSlice';
import memberSlice from '@/lib/member/memberSlice';
import reportSlice from '@/lib/report/reportSlice';
import timesheetSlice from '@/lib/timesheet/timesheetSlice';
import equipmentSlice from '@/lib/equipment/equipmentSlice';
import scheduleSlice from '@/lib/schedule/scheduleSlice';
import activitySlice from '@/lib/activity/activitySlice';
import newsfeedSlice from '@/lib/newsfeed/newsfeedSlice';
import teamsSlice from '@/lib/teams/teamsSlice';
import collectionSettingSlice from '@/lib/collections/collectionWorkflowSlice';
import formTemplateSlice from '@/lib/form/formTemplates/formTemplatesSlice';
import formTemplateSettingSlice from '@/lib/form/formTemplateSetting/formTemplateSettingSlice';
import submissionTemplateSettingSlice from '@/lib/submissionTemplates/submissionTemplateSettingSlice';
import submissionTemplateSlice from '@/lib/submissionTemplates/submissionTemplatesSlice';
import submissionsSlice from '@/lib/submissions/submissionsSlice';
import projectSlice from '@/lib/projects/projectSlice';
import webSlice from '@/lib/web/webSlice';
// RTKQuery
import { groupApi } from '@/lib/group/groupAPI';
import { newsfeedApi } from '@/lib/newsfeed/newsfeedAPI';
import { projectsApi } from '@/lib/projects/projectsAPI';
import { budgetApi } from '@/lib/budget/budgetAPI';
import { taskListApi } from '@/lib/taskList/taskListAPI';
import { usersApi } from '@/lib/users/usersAPI';
import { reportAPI } from '@/lib/report/reportAPI';
import { activityAPI } from '@/lib/activity/activityAPI';
import { notifyMessageApi } from '@/lib/notifyMessage/notifyMessageAPI';
import { scheduleApi } from '@/lib/schedule/scheduleAPI';
import { clockApi } from '@/lib/clock/clockAPI';
import { equipmentApi } from '@/lib/equipment/equipmentAPI';
import { memberApi } from '@/lib/member/memberAPI';
import { timesheetApi } from '@/lib/timesheet/timesheetAPI';
import { groupSettingApi } from '@/lib/groupSetting/groupSettingAPI';
import { teamsApi } from '@/lib/teams/teamsAPI';
import { formCollectionApi } from '@/lib/form/formCollectionAPI';
import { formTemplateSettingApi } from '@/lib/form/formTemplateSetting/formTemplateSettingAPI';
import { formTemplatesApi } from '@/lib/form/formTemplates/formTemplatesAPI';
import { formFilesApi } from '@/lib/form/formFIles/formFIlesAPI';
import { submissionTemplateSettingApi } from '@/lib/submissionTemplates/submissionTemplateSettingAPI';
import { submissionTemplatesApi } from '@/lib/submissionTemplates/submissionTemplatesAPI';
import { submissionApi } from '@/lib/submissions/submissionsAPI';

const store = configureStore({
  reducer: {
    user: userSlice,
    adminConsoleMember: adminConsoleMemberSlice,
    account: accountSlice,
    payment: paymentSlice,
    taskList: taskListSlice,
    app: appSlice,
    group: groupSlice,
    dialogs: dialogsSlice,
    newsfeed: newsfeedSlice,
    activity: activitySlice,
    clock: clockSlice,
    schedule: scheduleSlice,
    equipment: equipmentSlice,
    timesheet: timesheetSlice,
    report: reportSlice,
    member: memberSlice,
    teams: teamsSlice,
    collectionSetting: collectionSettingSlice,
    formTemplate: formTemplateSlice,
    formTemplateSetting: formTemplateSettingSlice,
    submissionTemplateSetting: submissionTemplateSettingSlice,
    submissionTemplate: submissionTemplateSlice,
    submissions: submissionsSlice,
    project: projectSlice,
    web: webSlice,
    // RTKQuery
    [groupApi.reducerPath]: groupApi.reducer,
    [newsfeedApi.reducerPath]: newsfeedApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [budgetApi.reducerPath]: budgetApi.reducer,
    [taskListApi.reducerPath]: taskListApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [reportAPI.reducerPath]: reportAPI.reducer,
    [activityAPI.reducerPath]: activityAPI.reducer,
    [scheduleApi.reducerPath]: scheduleApi.reducer,
    [notifyMessageApi.reducerPath]: notifyMessageApi.reducer,
    [clockApi.reducerPath]: clockApi.reducer,
    [equipmentApi.reducerPath]: equipmentApi.reducer,
    [memberApi.reducerPath]: memberApi.reducer,
    [timesheetApi.reducerPath]: timesheetApi.reducer,
    [groupSettingApi.reducerPath]: groupSettingApi.reducer,
    [teamsApi.reducerPath]: teamsApi.reducer,
    [formTemplatesApi.reducerPath]: formTemplatesApi.reducer,
    [formCollectionApi.reducerPath]: formCollectionApi.reducer,
    [formTemplateSettingApi.reducerPath]: formTemplateSettingApi.reducer,
    [formFilesApi.reducerPath]: formFilesApi.reducer,
    [submissionTemplateSettingApi.reducerPath]:
      submissionTemplateSettingApi.reducer,
    [submissionTemplatesApi.reducerPath]: submissionTemplatesApi.reducer,
    [submissionApi.reducerPath]: submissionApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      groupApi.middleware,
      newsfeedApi.middleware,
      memberApi.middleware,
      equipmentApi.middleware,
      projectsApi.middleware,
      budgetApi.middleware,
      taskListApi.middleware,
      usersApi.middleware,
      reportAPI.middleware,
      activityAPI.middleware,
      scheduleApi.middleware,
      notifyMessageApi.middleware,
      clockApi.middleware,
      equipmentApi.middleware,
      memberApi.middleware,
      timesheetApi.middleware,
      groupSettingApi.middleware,
      teamsApi.middleware,
      formTemplatesApi.middleware,
      formCollectionApi.middleware,
      formTemplateSettingApi.middleware,
      formFilesApi.middleware,
      submissionTemplateSettingApi.middleware,
      submissionTemplatesApi.middleware,
      submissionApi.middleware,
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
