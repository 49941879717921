'use client';

import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

const AnimationText = ({ words, t }) => {
  const [index, setIndex] = useState(0);

  const translatedWord = t[words[index].toLowerCase()] || words[index];

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 1200);

    return () => {
      clearInterval(timer);
    };
  }, [words.length]);
  return (
    <AnimatePresence>
      <motion.div
        key={index}
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 0 }}
        transition={{
          duration: 0.6,
          ease: 'easeInOut',
          type: 'spring',
          bounce: 0.6,
        }}
        className={`absolute left-0 top-14 flex h-[56px] w-full items-center justify-center bg-gradient-to-r from-primary-900 from-5% via-secondary-500 via-90% to-secondary-500 to-5% bg-clip-text text-5xl font-bold text-gradient lg:left-1/2 lg:top-0 lg:h-[102px] lg:w-auto lg:text-[88px] lg:leading-[96px]`}
      >
        {translatedWord}
      </motion.div>
    </AnimatePresence>
  );
};

export default AnimationText;
