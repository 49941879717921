import(/* webpackMode: "eager" */ "/app/packages/website/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/packages/website/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/website/src/app/[lang]/(web)/_components/Navbar.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/website/src/assets/images/appstore/AppStore.png");
;
import(/* webpackMode: "eager" */ "/app/packages/website/src/assets/images/appstore/GooglePlay.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/website/src/components/UI/Button/ButtonTryFree.jsx");
