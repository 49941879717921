import { createApi } from '@reduxjs/toolkit/query/react';
import { AxiosResponse } from 'axios';
import { ApiResponse, ByPageData } from '@/types/interface';
import {
  Brief,
  BriefQueryParams,
  CreateSubformParams,
  CreateSubmissionRequest,
  ProcessDetailRequest,
  SaveStepRequest,
  Submission,
  SubmissionDetail,
  SubmissionProgress,
  SubmissionsQuery,
  SubmissionTodoByPage,
  SubmissionTodoParams,
  SubmitStepRequest,
} from '@/lib/submissions/submissions.interface';
import { axiosBaseQuery } from '../axiosBaseQuery';

export const submissionApi = createApi({
  reducerPath: 'submissionApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'submissionApi',
    'filledSubmission',
    'trashedSubmission',
    'submissionProgress',
    'filledSubmissionSubForm',
    'submissionFormTodo',
  ],
  endpoints: (builder) => ({
    createSubmission: builder.mutation<any, CreateSubmissionRequest>({
      query: (body) => ({
        url: '/api/Submissions',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['submissionApi'],
      transformResponse: (response: AxiosResponse) => response.data.data,
    }),
    getSubmissions: builder.query<Submission, SubmissionsQuery>({
      query: (params) => ({
        url: `/api/Submissions`,
        method: 'GET',
        params,
      }),
      transformResponse: (response: AxiosResponse) => response.data.data,
      providesTags: ['submissionApi', 'filledSubmission'],
    }),
    getSubmissionDetail: builder.query<SubmissionDetail, number>({
      query: (submissionId) => ({
        url: `/api/Submissions/${submissionId}/Detail`,
        method: 'GET',
      }),
      transformResponse: (response: AxiosResponse) => response.data.data,
      providesTags: ['filledSubmission'],
    }),
    // 一次性的查詢
    getSubmissionDetailOneTime: builder.query<SubmissionDetail, number>({
      query: (submissionId) => ({
        url: `/api/Submissions/${submissionId}/Detail`,
        method: 'GET',
      }),
      transformResponse: (response: AxiosResponse) => response.data.data,
    }),
    // subForm
    getSubmissionDetailSubForm: builder.query<any, number>({
      query: (submissionId) => ({
        url: `/api/Submissions/${submissionId}/Detail`,
        method: 'GET',
      }),
      providesTags: ['filledSubmissionSubForm'],
      transformResponse: (response: AxiosResponse) => response.data.data,
    }),
    submitStep: builder.mutation<any, SubmitStepRequest>({
      query: (body) => ({
        url: `/api/Submissions/SubmitStep`,
        method: 'POST',
        data: body,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          submissionApi.util.invalidateTags([
            { type: 'filledSubmission' as const },
            { type: 'submissionProgress' as const },
            { type: 'filledSubmissionSubForm' as const },
          ]),
        );
      },
    }),
    saveStep: builder.mutation<any, SaveStepRequest>({
      query: (data) => ({
        url: `/api/Submissions/SaveStep`,
        method: 'PUT',
        data,
      }),
    }),
    subformSaveStep: builder.mutation<any, SaveStepRequest>({
      query: (data) => ({
        url: `/api/Submissions/SaveStep`,
        method: 'PUT',
        data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          submissionApi.util.invalidateTags([
            { type: 'filledSubmissionSubForm' as const },
          ]),
        );
      },
    }),
    getSubmissionProgress: builder.query<SubmissionProgress, number>({
      query: (submissionId) => ({
        url: `/api/Submissions/${submissionId}/Progress`,
        method: 'GET',
      }),
      transformResponse: (response: AxiosResponse) => response.data.data,
      providesTags: ['submissionProgress'],
    }),
    getSubmissionProgressSubForm: builder.query<SubmissionProgress, number>({
      query: (submissionId) => ({
        url: `/api/Submissions/${submissionId}/Progress`,
        method: 'GET',
      }),
      transformResponse: (response: AxiosResponse) => response.data.data,
    }),
    batchMoveToTrash: builder.mutation<any, { submissionIds: number[] }>({
      query: (data) => ({
        url: `/api/Submissions/BatchMoveToTrash`,
        method: 'DELETE',
        data,
      }),
      invalidatesTags: [
        'submissionApi',
        'trashedSubmission',
        'submissionFormTodo',
      ],
      transformResponse: (response) => response.data.data,
    }),
    batchRestore: builder.mutation<any, { submissionIds: number[] }>({
      query: (data) => ({
        url: `/api/Submissions/BatchRestore`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['submissionApi', 'trashedSubmission'],
      transformResponse: (response) => response.data.data,
    }),
    getNewOneOverview: builder.query<any, number>({
      query: (templateId) => ({
        url: `/api/Submissions/NewOneOverview`,
        method: 'GET',
        params: { templateId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    getTrashed: builder.query<any, number>({
      query: (templateId) => ({
        url: `/api/Submissions/GetTrashed`,
        method: 'GET',
        params: { templateId },
      }),
      providesTags: ['trashedSubmission'],
      transformResponse: (response) => response.data.data,
    }),
    batchDeleteForever: builder.mutation<any, { submissionIds: number[] }>({
      query: (data) => ({
        url: `/api/Submissions/BatchDeleteForever`,
        method: 'DELETE',
        data,
      }),
      invalidatesTags: ['trashedSubmission'],
      transformResponse: (response) => response.data.data,
    }),
    clearTrashed: builder.mutation<boolean, number>({
      query: (templateId) => ({
        url: `/api/Submissions/ClearTrashed/${templateId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['trashedSubmission'],
      transformResponse: (response) => response.data.data,
    }),
    getBrief: builder.query<Brief, BriefQueryParams>({
      query: (params) => ({
        url: `/api/Submissions/Brief`,
        method: 'GET',
        params,
      }),
      transformResponse: (response) => response.data.data,
    }),
    getProcessDetail: builder.query<any, ProcessDetailRequest>({
      query: (params) => ({
        url: `/api/Submissions/${params.submissionId}/ProcessDetail`,
        method: 'GET',
        params,
      }),
      transformResponse: (response) => response.data.data,
    }),
    createSubform: builder.mutation<any, CreateSubformParams>({
      query: (data) => ({
        url: `/api/Submissions/CreateSubform`,
        method: 'POST',
        data,
      }),
      transformResponse: (response) => response.data.data,
    }),
    getSubmissionTodos: builder.query<
      SubmissionTodoByPage,
      SubmissionTodoParams
    >({
      query: (params) => ({
        url: `/api/Submissions/Todo`,
        method: 'GET',
        params,
      }),
      transformResponse: (response) => response.data.data,
      providesTags: ['submissionFormTodo'],
    }),
  }),
});

export const {
  useCreateSubmissionMutation,
  useGetSubmissionsQuery,
  useGetSubmissionDetailQuery,
  useGetSubmissionDetailOneTimeQuery,
  useGetSubmissionDetailSubFormQuery,
  useSubmitStepMutation,
  useSaveStepMutation,
  useSubformSaveStepMutation,
  useGetSubmissionProgressQuery,
  useGetSubmissionProgressSubFormQuery,
  useBatchMoveToTrashMutation,
  useBatchRestoreMutation,
  useGetNewOneOverviewQuery,
  useGetTrashedQuery,
  useBatchDeleteForeverMutation,
  useClearTrashedMutation,
  useGetBriefQuery,
  useGetProcessDetailQuery,
  useCreateSubformMutation,
  useGetSubmissionTodosQuery,
} = submissionApi;
