import { ProjectDetail } from '@/lib/projects/Interface';
import { projectsApi } from '@/lib/projects/projectsAPI';
import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  detail: ProjectDetail | null;
}

const initialState: InitialState = {
  detail: null,
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      projectsApi.endpoints.getProjectDetail.matchFulfilled,
      (state, action) => {
        state.detail = action.payload;
      },
    );
  },
});

export default projectSlice.reducer;
