import React from 'react';
import { Plan } from '@/app/[lang]/(web)/_components/PlanSection';
import { BaseIcon } from '@/components/base/BaseIcon/BaseIcon';
import Link from 'next/link';

interface PlanCardProps {
  plan: Plan;
  isYearly: boolean;
  t: any;
  group?: any[];
  lang: string;
}

const PlanCard = ({ plan, isYearly, t, group, lang }: PlanCardProps) => {
  const currentPrice = isYearly ? plan.price.yearly : plan.price.monthly;

  const link = () => {
    if (group && group?.length > 0) {
      return `/${lang}/admin/${group[0]?.groupId}/chooseplan`;
    }
    return `/${lang}/signup`;
  };

  const cardStyle = {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: plan.color,
    borderBottomWidth: '6px',
  } as const;

  const buttonStyle = {
    backgroundColor: currentPrice === 0 ? '#FFF' : plan.color,
    color: currentPrice === 0 ? plan.color : '#FFF',
    borderWidth: currentPrice === 0 ? '1px' : '0px',
    borderStyle: currentPrice === 0 ? 'solid' : 'none',
    borderColor: currentPrice === 0 ? plan.color : 'transparent',
  } as const;

  const titleStyle = {
    color: plan.color,
  } as const;
  return (
    <div className="h-fit w-full rounded-2xl bg-white p-5" style={cardStyle}>
      <div>
        <span className="typo-paragraph-xxl font-bold" style={titleStyle}>
          {t[plan.title]}
        </span>
        <p className="typo-paragraph-sm">{t[plan.suitable]}</p>
      </div>
      <div className="flex flex-col items-center justify-center gap-4 py-4">
        <p className="text-[56px] font-bold leading-[64px]">
          {currentPrice === 0 ? (
            t.planFree
          ) : (
            <span>
              <span className="typo-heading-xs">$</span>
              {currentPrice}
              <span className="typo-heading-xs">{t.planUSD}</span>
            </span>
          )}
        </p>
        <div className="flex w-full flex-col items-center gap-2">
          <Link
            href={link()}
            className="w-full rounded-[10px] py-[14px] text-center hover:shadow-lg"
            style={buttonStyle}
            type="button"
          >
            {t[plan.buttonText]}
          </Link>
          {currentPrice !== 0 && (
            <p className="typo-paragraph-xs text-gray-700">
              {t.planPerUserPerMonth}
            </p>
          )}
        </div>
      </div>
      <div>
        <p className="typo-label-xs pb-1 uppercase text-gray-700">
          {t.planKeyFeature}
        </p>
        <ul className="flex list-disc flex-col gap-1">
          {plan.features.map((feature) => (
            <li
              className="typo-paragraph-xs flex list-none items-center gap-1"
              key={feature}
            >
              <span>
                <BaseIcon name="CheckDefault" size={16} color="#262D42" />
              </span>
              {t[feature]}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PlanCard;
