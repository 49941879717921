export enum TaskListSubTaskStatusEnum {
  Open = 1,
  InProgress = 2,
  OnHold = 3,
  Done = 4,
}

export enum TaskListStatusEnum{
  InProgress = 1,
  Done = 2,
  Overdue = 3,
}

export enum TaskListHistoryTemplateEnum {
  Created = 1,
  AddTasks = 2,
  DeleteTasks = 3,
  ChangeDueDate = 4,
  TaskListSubTaskUpdateStatus = 5,
  TaskListSubTaskAssign = 6,
  TaskListSubTaskTagDrawing = 7,
}

export enum TaskMenuStatusEnum {
  All = 1,
  My = 2,
  Overdue = 3,
  Done = 4,
}
