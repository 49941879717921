'use client';

import { useEffect, useState } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import Link from 'next/link';
import GBGIcon from '@/assets/icons/GB-G-icon.svg';

import gaEvent from '@/utils/ga';

export default function ButtonTryFree({ t }) {
  const [isToken, setIsToken] = useState(false);
  const [windowHeight, setWindowHeight] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [shouldShow, setShouldShow] = useState(false);

  const { scrollY } = useScroll();

  // 處理視窗高度
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    // 初始設定
    handleResize();

    // 監聽視窗大小變化
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // 處理 token
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token !== null) {
      setIsToken(true);
    }
  }, []);

  // 處理滾動事件
  useEffect(() => {
    if (windowHeight === 0) return;

    let scrollTimeout;
    const handleScroll = () => {
      // 設置滾動狀態
      setIsScrolling(true);
      clearTimeout(scrollTimeout);

      scrollTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 3000);

      // 控制按鈕顯示
      const scrollPosition = window.scrollY;
      setShouldShow(scrollPosition > windowHeight * 1.5);
    };

    // 初始檢查
    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, [windowHeight]);

  const opacity = useTransform(
    scrollY,
    [0, windowHeight * 0.3],
    [0, 1],
    { clamp: true }, // 確保值在 0-1 範圍內
  );

  if (isToken || windowHeight === 0 || !shouldShow) return null;

  return (
    <div className="fixed bottom-10 left-0 right-0 z-40 mx-auto w-full transition-all duration-300 md:left-auto md:right-10 md:mx-0 md:w-auto">
      <motion.div
        style={{ opacity }}
        animate={{
          y: isScrolling ? 100 : 0,
          opacity: isScrolling ? 0 : 1,
        }}
        transition={{
          duration: 0.3,
          ease: 'easeInOut',
        }}
        className="mx-auto w-fit md:mx-0"
      >
        <Link href="/signup">
          <button
            onClick={() => {
              const eventPage =
                window.location.pathname.split('/')[1].toLowerCase() || 'index';

              gaEvent(`try_for_free_click_${eventPage}`, {
                event_category: 'Click',
                event_page: eventPage || 'homePage',
                value: 100,
              });
            }}
            type="button"
            className="flex items-center whitespace-nowrap rounded-full border-[3px] border-white bg-secondary-600 px-5 py-2 transition-all duration-200 ease-in-out hover:-translate-x-[2px] hover:-translate-y-1 hover:bg-secondary-700 hover:shadow-[2px_5px_0_0_#14E869] active:translate-x-0 active:translate-y-[2px] active:shadow-[0_0_0_0_#14E869]"
          >
            <p
              className={`mr-1 text-[16px] font-normal leading-[24px] text-white lg:text-[20px] lg:leading-[28px]`}
            >
              {t.tryForFreeButton}
            </p>
            <GBGIcon />
          </button>
        </Link>
      </motion.div>
    </div>
  );
}
